import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { useRouter } from 'next/dist/client/router';
import { ChangeEventHandler, FC, useMemo } from 'react';
import useSWR from 'swr';

import Select from '~components/ui/Select';
import parseQuery from '~utils/api/parseQuery';
import go from '~utils/go';

import { Cinema } from '~types';

import s from './BillboardFilters.module.css';
import DateSelector from './DateSelector';

dayjs.extend(isToday);

interface Props {
  label?: string;
  dates: string[];
  date: string;
  cinema: string | null;
  baseHrefPath: string;
  onChange?: () => void;
  className?: string;
  baseUrl?: string;
  revalidateCinemasOnMount?: boolean;
}

const BillboardFilters: FC<Props> = ({
  dates,
  label,
  date,
  cinema,
  className,
  baseUrl = '/',
  baseHrefPath,
  revalidateCinemasOnMount = false,
}) => {
  const { data: cinemas } = useSWR<Cinema[]>('/cinemas/list', {
    revalidateOnMount: revalidateCinemasOnMount,
  });
  const { query } = useRouter();
  const selectedCinema = useMemo<Cinema | undefined>(
    () => cinemas?.find?.((item) => `${item.id}` === `${cinema}`),
    [cinema],
  );

  const handleCinemaChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const path = `${baseUrl}?${parseQuery({
      c: e.target.value,
      ...(query.d || query.date ? { d: query.d || query.date } : {}),
    })}`;
    go(path, { shallow: true, scroll: false });
  };

  const handleDateChange = (newDate: string) => {
    const path = `${baseUrl}?${parseQuery({
      d: newDate,
      ...(query.c || query.CinemaId ? { c: query.c || query.CinemaId } : {}),
    })}`;
    go(path, { shallow: true, scroll: false });
  };

  const setNewPath = (newDate: string) => {
    if (!Array.isArray(cinemas)) {
      return '#';
    }

    const selectedCinema = cinemas?.find((item) => `${item.id}` === `${cinema}`);
    if (dayjs(newDate).isToday()) {
      return `${baseHrefPath}${selectedCinema?.slug ?? ''}`;
    }
    return `${baseHrefPath}${selectedCinema?.slug ?? ''}${
      selectedCinema?.slug ? '/' : ''
    }${newDate}`;
  };

  return (
    <>
      <div className={className}>
        <div className={s.wrapper}>
          {!!label && <span className={s.label}>{label}</span>}
          <Select
            placeholder="Selecciona un cine"
            className={s.cinemas}
            type="primary"
            onChange={handleCinemaChange}
            value={cinema || ''}
          >
            <option value="">Selecciona un cine</option>
            {cinemas?.map?.((cinema) => (
              <option value={cinema.id} key={cinema.id}>
                {cinema.name}
              </option>
            ))}
          </Select>
        </div>
        <div className={s.dates}>
          {dates && dates.length > 0 && !!DateSelector ? (
            <DateSelector
              date={date}
              dates={dates}
              onChange={handleDateChange}
              timezone={selectedCinema?.timezone || 'America/Mazatlan'}
              {...(cinema ? { getHref: setNewPath } : {})}
            />
          ) : null}
        </div>
      </div>
      {!!selectedCinema && !selectedCinema.onlineSale && (
        <div className="col-span-2 text-center border-amber-400 bg-warning my-8 p-4 rounded">
          <span className="h3 block">Venta en línea no disponible</span>
          <span>
            La venta de boletos en línea no esta disponible por el momento para este complejo.
          </span>
        </div>
      )}
    </>
  );
};

export default BillboardFilters;
